import { useObserver } from 'mobx-react-lite';
import React from 'react';

import { useStores } from '../../hooks/use-stores';
import { DeviceConfigurationParams } from '../../models/deviceConfiguration';

import { ViewState, defaultConfiguration } from './DeviceConfigurationHelper';
import { DeviceConfigurationList, ListItem } from './DeviceConfigurationList';
import { DeviceConfigurationSettings } from './DeviceConfigurationSettings';

export const DeviceConfiguration = (): JSX.Element | null => {
  const { deviceStore, tenantStore, deviceConfigurationStore, userStore } = useStores();
  const [currentView, setCurrentView] = React.useState<ViewState>(ViewState.list);
  const [selectedConfiguration, setSelectedConfiguration] =
    React.useState<DeviceConfigurationParams>();

  return useObserver(() => {
    if (!tenantStore.currentTenant || !userStore.currentUser) {
      return null;
    }

    deviceStore.initData(tenantStore.currentTenant.id as string);
    deviceConfigurationStore.initData(tenantStore.currentTenant.id as string);

    const { currentUser } = userStore;

    const listConfigurationItems = deviceConfigurationStore.deviceConfigurations?.map(
      (deviceConfigDoc) => ({
        onItemSelect: (item: ListItem): void => {
          const filteredConfig = deviceConfigurationStore.deviceConfigurations?.find(
            (doc) => doc.name === item.title
          );

          if (filteredConfig) {
            setSelectedConfiguration({
              ...filteredConfig,
              rideSettings: filteredConfig.rideSettings
            });
            setCurrentView(ViewState.edit);
          }
        },
        title: deviceConfigDoc.name
      })
    );

    const updateDeviceConfigurationSettings = async (
      currentConfiguration: DeviceConfigurationParams
    ): Promise<void> => {
      if (currentView === ViewState.new) {
        await deviceConfigurationStore.addNewConfiguration(currentConfiguration);
      } else {
        await deviceConfigurationStore.updateConfig(currentConfiguration);
      }
      setSelectedConfiguration(undefined);
      setCurrentView(ViewState.list);
    };

    return selectedConfiguration && currentView !== ViewState.list ? (
      <DeviceConfigurationSettings
        numberOfDevicesWithSelectedConfig={deviceStore.getNumberOfDevicesWithConfig(
          selectedConfiguration.id
        )}
        configuration={selectedConfiguration}
        updateDeviceConfiguration={updateDeviceConfigurationSettings}
        updateCurrentView={(view: ViewState): void => setCurrentView(view)}
        currentView={currentView}
        editable={currentUser.hasAdminAccessOnSelectedTenant()}
      />
    ) : (
      <DeviceConfigurationList
        listItems={listConfigurationItems || []}
        editable={currentUser.hasAdminAccessOnSelectedTenant()}
        onButtonClick={(): void => {
          setSelectedConfiguration(defaultConfiguration); // creates new empty config object
          setCurrentView(ViewState.new);
        }}
      />
    );
  });
};
