import { ModalBody, ModalHeader } from 'baseui/modal';
import { StyleObject, StyletronComponent } from 'styletron-react';

import { themedStyled, themedWithStyle } from '../../theme/theme';

export type LogType = 'logs' | 'errors';

export const StyledModalBody: StyletronComponent<typeof ModalBody, {}> = themedWithStyle(
  ModalBody,
  (): StyleObject => ({
    height: 'auto',
    width: '50vw'
  })
);

export const BodyText: StyletronComponent<'span', {}> = themedStyled(
  'span',
  ({ $theme }): StyleObject => ({
    ...$theme.typography.contentBodyRegular,
    color: $theme.colors.text100,
    marginBottom: $theme.sizes.scale400
  })
);

export const BoldBodyText: StyletronComponent<'strong', {}> = themedStyled(
  'strong',
  ({ $theme }): StyleObject => ({
    ...$theme.typography.contentBodyBold
  })
);

export const StyledModalHeader = themedWithStyle(
  ModalHeader,
  ({ $theme }): StyleObject => ({
    ...$theme.typography.headingLargeSemiBold,
    color: $theme.colors.text100
  })
);

export const ModalFooter: StyletronComponent<'div', {}> = themedStyled(
  'div',
  ({ $theme }): StyleObject => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: $theme.sizes.scale500,
    marginLeft: $theme.sizes.scale500,
    marginRight: $theme.sizes.scale500,
    marginTop: $theme.sizes.scale500
  })
);

export const WarningMessage: StyletronComponent<'p', {}> = themedStyled(
  'p',
  ({ $theme }): StyleObject => ({
    ...$theme.typography.headingMediumRegular,
    margin: 'auto'
  })
);

export const CenterContainer: StyletronComponent<'div', {}> = themedStyled('div', () => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-start',
  width: '100%'
}));
