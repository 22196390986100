import React from 'react';
import { StyleObject } from 'styletron-react';

import { DeviceDocument } from '../../models';
import { DeleteOutlineIcon } from '../../theme/icons';
import { themedStyled } from '../../theme/theme';
import { Button } from '../button/Button';

const StyledButton = themedStyled(
  Button,
  ({ $theme }): StyleObject => ({
    marginRight: $theme.sizes.scale100
  })
);

interface DeviceListProps {
  devices: DeviceDocument[];
  onClicked: () => void;
}

export const DeviceListRemoveDevices = ({ devices, onClicked }: DeviceListProps): JSX.Element => (
  <StyledButton
    variant='primary'
    disabled={devices.length === 0}
    icon={DeleteOutlineIcon}
    onClick={onClicked}
  >
    Remove
  </StyledButton>
);
