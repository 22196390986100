import { Modal, ModalButton } from 'baseui/modal';
import React from 'react';

import { StyledModalHeader, ModalFooter } from '../modal/ModalHelper';

import { FirmwareModalBody } from './FirmwareModalBody';

export interface FirmwareModalProps {
  firmwarePayload: string;
  isOpen: boolean;
  onChange: (payload: string) => void;
  onClose: () => void;
  onSubmit: () => void;
}

const validateInput = (
  input: string,
  setIsInputValid: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  try {
    JSON.parse(input);
    setIsInputValid(true);
  } catch {
    setIsInputValid(false);
  }
};

export const FirmwareModal = ({
  firmwarePayload,
  isOpen,
  onClose,
  onSubmit,
  onChange,
}: FirmwareModalProps): JSX.Element => {
  const [isInputValid, setIsInputValid] = React.useState(false);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      closeable={false}
      overrides={{
        Dialog: {
          style: {
            width: 'auto',
          },
        },
      }}
    >
      <StyledModalHeader>Update Firmware</StyledModalHeader>

      <FirmwareModalBody
        isInputValid={isInputValid}
        firmwarePayload={firmwarePayload}
        onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
          onChange(e.currentTarget.value);
          validateInput(e.currentTarget.value, setIsInputValid);
        }}
      />

      <ModalFooter>
        <ModalButton kind="secondary" onClick={onClose}>
          Cancel
        </ModalButton>
        <ModalButton kind="primary" onClick={onSubmit} disabled={!isInputValid}>
          Update Firmware
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};
