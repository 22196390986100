import { makeAutoObservable, runInAction } from 'mobx';
import Cookie from 'mobx-cookie';

import { IOTClient } from '../client/interfaces';
import { TenantConfig, TenantDocument } from '../models';
import { TenantAccessInfo } from '../models/userDocument';

import { UserStore } from './userStore';

export class TenantStore {
  currentTenant?: TenantDocument;

  tenants: TenantAccessInfo[] | null = null;

  readonly cookie = new Cookie('tenantId');

  constructor(readonly userStore: UserStore, readonly iotClient: IOTClient) {
    makeAutoObservable(this, {
      cookie: false,
      userStore: false,
      iotClient: false
    });
  }

  async setCurrentTenant(tenantId: string): Promise<void> {
    const { success, tenant } = await this.iotClient.getTenant(tenantId);
    if (success && tenant) {
      runInAction(() => {
        this.currentTenant = new TenantDocument(tenant);
      });
      this.setCookie(tenantId);
      this.userStore.currentUser?.setTenant(tenant.id);
      this.iotClient.setBaseURL(this.currentTenant?.iotConnector?.baseUrl ?? '');
    }
  }

  public getTenantId(): string | undefined {
    if (this.currentTenant) {
      return this.currentTenant.id;
    }

    if (
      this.cookie.value &&
      this.tenants?.find(({ id }: { id: string }) => id === this.cookie.value)
    ) {
      return this.cookie.value;
    }

    if (this.tenants && this.tenants.length > 0) {
      return this.tenants[0].id;
    }

    return undefined;
  }

  async initData(tenants: TenantAccessInfo[]): Promise<void> {
    if (!this.tenants) {
      runInAction(() => {
        this.tenants = tenants;
      });
      
      const initialTenantFromCookie = tenants.find(
        (tenant: TenantAccessInfo) => tenant.id === this.cookie.value
      );
      if (initialTenantFromCookie) {
        this.iotClient.setBaseURL(initialTenantFromCookie.apiUrl);
        await this.setCurrentTenant(initialTenantFromCookie.id);
      }
      if (!this.currentTenant && tenants.length > 0) {
        this.iotClient.setBaseURL(tenants[0].apiUrl);
        await this.setCurrentTenant(tenants[0].id);
      }
    }
  }

  async updateTenant(tenantConfig: TenantConfig): Promise<void> {
    const { success, tenant } = await this.iotClient.updateTenant(tenantConfig);
    if (success && tenant) {
      runInAction(() => {
        this.currentTenant = new TenantDocument(tenant);
      });
    }
  }

  private setCookie(tenantId: string): void {
    this.cookie.set(tenantId);
  }
}
