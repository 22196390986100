/* eslint-disable @typescript-eslint/no-explicit-any */
import { ALIGN, StyledNavigationItem, StyledNavigationList } from 'baseui/header-navigation';
import { Select } from 'baseui/select';
import React, { useEffect, useState } from 'react';
import { StyleObject } from 'styletron-react';
import SwaggerUI from 'swagger-ui';

import 'swagger-ui/dist/swagger-ui.css';
import { useStores } from '../../hooks/use-stores';
import { themedUseStyletron as useStyletron } from '../../theme/theme';
import { Button } from '../button/Button';

const apiOptions = [
  { id: 'fleet', label: 'IoT Connector API' },
  { id: 'auth', label: 'IoT Connector Auth API' }
];

export const ApiDocumentation = (): JSX.Element => {
  const [, theme] = useStyletron();
  const { featureStore, tenantStore } = useStores();
  const [selectedApi, setSelectedApi] = useState(apiOptions[0]);
  const [apiSpec, setApiSpec] = useState({ authDoc: {}, fleetDoc: {} });
  const tenantId = tenantStore.getTenantId() as string;
  const spec = selectedApi.id === apiOptions[0].id ? apiSpec.fleetDoc : apiSpec.authDoc;

  useEffect(() => {
    const getApiSpecs = async () => {
      const apiSpecResponse = await featureStore.getDocumentation(tenantId);
      setApiSpec(apiSpecResponse as any);
    };

    getApiSpecs();
  }, []);

  useEffect(() => {
    SwaggerUI({
      dom_id: '#swagger-ui',
      spec
    });
  }, [selectedApi, apiSpec]);

  const handleDownload = () => {
    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(spec)], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `${selectedApi.label}.json`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <div
      style={{
        color: theme.colors.textWhite,
        paddingBottom: theme.sizes.scale200,
        paddingRight: theme.sizes.scale500,
        paddingTop: theme.sizes.scale200
      }}
    >
      <StyledNavigationList $align={ALIGN.right}>
        <StyledNavigationItem>
          <Select
            clearable={false}
            searchable={false}
            options={apiOptions}
            value={[selectedApi]}
            placeholder='Select an API document'
            onChange={(params) => {
              setSelectedApi(params.value[0] as any);
            }}
            overrides={{
              ControlContainer: {
                style: (): StyleObject => ({
                  backgroundColor: theme.colors.bg90,
                  border: 'none',
                  color: theme.colors.textWhite
                })
              },
              DropdownContainer: {
                style: (): StyleObject => ({
                  width: 'auto'
                })
              },
              InputContainer: {
                style: (): StyleObject => ({
                  color: theme.colors.textWhite
                })
              },
              SelectArrow: {
                style: (): StyleObject => ({
                  color: theme.colors.textWhite,
                  height: theme.sizes.scale500,
                  marginRight: `-${theme.sizes.scale300}`,
                  width: theme.sizes.scale500
                })
              }
            }}
          />
        </StyledNavigationItem>
        <StyledNavigationItem>
          <Button
            variant='text'
            onClick={handleDownload}
            style={{
              ...theme.typography.contentBodyRegular,
              ':hover:enabled': {
                backgroundColor: theme.colors.bg90,
                color: theme.colors.textWhite
              },
              color: theme.colors.bgWhite,
              backgroundColor: theme.colors.borderAccent,
              height: '45px',
              padding: '0 16px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            Download
          </Button>
        </StyledNavigationItem>
      </StyledNavigationList>
      <div id='swagger-ui' />
    </div>
  );
};
