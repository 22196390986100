import {
  HeaderNavigation,
  ALIGN,
  StyledNavigationList,
  StyledNavigationItem
} from 'baseui/header-navigation';
import { Select, OnChangeParams } from 'baseui/select';
import { Observer, observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { StyleObject } from 'styletron-react';

import { ReactComponent as IotConnectorLogo } from '../../assets/icons/iot-connector-logo.svg';
import { TENANT_SELECTOR, SIGN_OUT } from '../../e2e/testIds';
import { useStores } from '../../hooks/use-stores';
import { themedUseStyletron, themedStyled } from '../../theme/theme';
import { Button } from '../button/Button';
import { DeviceConfiguration } from '../deviceConfiguration/DeviceConfiguration';
import { Sidebar } from '../sidebar/Sidebar';
import { TenantConfiguration } from '../tenantConfiguration/TenantConfiguration';

type SelectOption = { label: string; id: string };

type Props = {
  version?: string;
  handleLogout: () => void;
};

const VersionInfo = themedStyled('span', ({ $theme }) => ({
  ...$theme.typography.labelSmallRegular,
  color: $theme.colors.textInactive
}));

export const Header = observer(function Header({ version, handleLogout }: Props) {
  const [showTenantConfiguration, setShowTenantConfiguration] = React.useState(false);
  const [, theme] = themedUseStyletron();
  const { uiStore, tenantStore, userStore, deviceStore } = useStores();

  const { currentUser } = userStore;

  if (currentUser) {
    tenantStore.initData(currentUser.allowedReadTenants);
  }

  const displayTenantSelector = (tenantStore.tenants || []).length > 1;

  const selectOptions: SelectOption[] = tenantStore.tenants
    ? tenantStore.tenants
        .map((tenant) => ({
          id: tenant.id || '',
          label: tenant.name || tenant.id || ''
        }))
        .sort((tenantA, tenantB) =>
          tenantA.label.toLowerCase() > tenantB.label.toLowerCase() ? 1 : -1
        )
    : [];

  const onTenantSelect = (event: OnChangeParams): void => {
    if (event.option && typeof event.option.id === 'string') {
      deviceStore.cleanUp();
      tenantStore.setCurrentTenant(event.option.id);
    }
  };

  return (
    <HeaderNavigation
      overrides={{
        Root: {
          style: (): StyleObject => ({
            backgroundColor: theme.colors.bg100,
            color: theme.colors.textWhite,
            paddingBottom: theme.sizes.scale200,
            paddingRight: theme.sizes.scale500,
            paddingTop: theme.sizes.scale200
          })
        }
      }}
    >
      <Link to='/' style={{ textDecoration: 'none', color: theme.colors.bgWhite }}>
        <StyledNavigationList $align={ALIGN.left} style={{ lineHeight: '0' }}>
          <StyledNavigationItem>
            <IotConnectorLogo />
          </StyledNavigationItem>
          <StyledNavigationItem>
            <p>IoT Connector</p>
            <VersionInfo>{version}</VersionInfo>
          </StyledNavigationItem>
        </StyledNavigationList>
      </Link>
      <StyledNavigationList $align={ALIGN.center}>
        <StyledNavigationItem />
      </StyledNavigationList>

      {currentUser && (
        <StyledNavigationList $align={ALIGN.right}>
          {displayTenantSelector && (
            <StyledNavigationItem>
              <Select
                clearable={false}
                searchable={false}
                options={selectOptions}
                value={
                  tenantStore.currentTenant?.id
                    ? [
                        {
                          id: tenantStore.currentTenant.id
                        }
                      ]
                    : undefined
                }
                placeholder='Select Tenant'
                onChange={onTenantSelect}
                overrides={{
                  ControlContainer: {
                    style: (): StyleObject => ({
                      backgroundColor: theme.colors.bg90,
                      borderColor: theme.colors.bg90,
                      borderWidthBottom: '1px',
                      borderWidthLeft: '1px',
                      borderWidthRight: '1px',
                      borderWidthTop: '1px',
                      color: theme.colors.textWhite
                    })
                  },
                  DropdownContainer: {
                    style: (): StyleObject => ({
                      width: 'auto'
                    })
                  },
                  InputContainer: {
                    style: (): StyleObject => ({
                      color: theme.colors.textWhite
                    })
                  },
                  Root: {
                    props: {
                      'data-test-id': TENANT_SELECTOR
                    }
                  },
                  SelectArrow: {
                    style: (): StyleObject => ({
                      color: theme.colors.textWhite,
                      height: theme.sizes.scale500,
                      marginRight: `-${theme.sizes.scale300}`,
                      width: theme.sizes.scale500
                    })
                  },
                  ValueContainer: {
                    style: (): StyleObject => ({
                      paddingBottom: theme.sizes.scale100,
                      paddingTop: theme.sizes.scale100,
                      width: 'auto'
                    })
                  }
                }}
              />
            </StyledNavigationItem>
          )}

          <StyledNavigationItem>
            <Button
              variant='text'
              onClick={(): void => uiStore.updateIsSidebarOpenValue(true)}
              style={{
                ...theme.typography.headingSmallRegular,
                ':focus': {
                  backgroundColor: theme.colors.bg90,
                  color: theme.colors.textWhite
                },
                ':hover:enabled': {
                  backgroundColor: theme.colors.bg90,
                  color: theme.colors.textWhite
                },
                color: theme.colors.bgWhite
              }}
            >
              Device Configuration
            </Button>
            <Observer>
              {(): JSX.Element => (
                <Sidebar
                  animate={true}
                  autoFocus={true}
                  closeable={true}
                  overrides={{}}
                  isOpen={uiStore.isSidebarOpen}
                  onClose={(): void => uiStore.updateIsSidebarOpenValue(false)}
                  size="default"
                  anchor="right"
                  showBackdrop={true}
                >
                  <DeviceConfiguration />
                </Sidebar>
              )}
            </Observer>
          </StyledNavigationItem>

          <StyledNavigationItem>
            <Button
              variant='text'
              onClick={(): void => setShowTenantConfiguration(true)}
              style={{
                ...theme.typography.headingSmallRegular,
                ':hover:enabled': {
                  backgroundColor: theme.colors.bg90,
                  color: theme.colors.textWhite
                },
                color: theme.colors.bgWhite
              }}
            >
              Settings
            </Button>
          </StyledNavigationItem>
          <StyledNavigationItem>
            <Button
              variant='text'
              onClick={handleLogout}
              style={{
                ...theme.typography.headingSmallRegular,
                ':hover:enabled': {
                  backgroundColor: theme.colors.bg90,
                  color: theme.colors.textWhite
                },
                color: theme.colors.bgWhite
              }}
              overrides={{
                BaseButton: {
                  props: {
                    'data-test-id': SIGN_OUT
                  }
                }
              }}
            >
              Sign out
            </Button>
          </StyledNavigationItem>
        </StyledNavigationList>
      )}

      {tenantStore.currentTenant && (
        <TenantConfiguration
          open={showTenantConfiguration}
          tenant={tenantStore.currentTenant}
          editable={Boolean(currentUser?.hasAdminAccessOnSelectedTenant())}
          onClose={(): void => setShowTenantConfiguration(false)}
        />
      )}
    </HeaderNavigation>
  );
});
