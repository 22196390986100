/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, computed, observable } from 'mobx';

import {
  DEVICE_TYPES,
  SUPPORTED_ACTIONS_UNDEFINED_VEHICLE
} from '@wunder/tools-iot-connector-device-types';

import {
  DeviceConfiguration,
  GeneralSettings,
  IotSettings,
  RideSettings
} from './deviceConfiguration';
import { DeviceEventDocument } from './deviceEventDocument';

export enum PowerSupply {
  Main = 'main',
  Backup = 'backup'
}

export type DeviceMeta = {
  deviceTypeId?: string;
  deviceConfigurationId?: string;
  vendor?: string;
  provisioned?: boolean;
  maxSpeed?: number;
  deviceName?: string;
  batterySerial?: string;
  firmwareUrl?: string;
  firmware?: {
    iotVersion?: string;
    iotCompilationDate?: string;
    mcuVersion?: string;
  };
  inactivated?: boolean;
  lastActiveAt?: string;
  timestamp?: string;
};

export type DeviceState = {
  backupBatteryLevel?: number;
  powerSupply?: PowerSupply;
  latitude: number;
  longitude: number;
  positionAccuracy: number;
  energyLevel: number | null;
  charging: boolean;
  locked: boolean;
  settings: GeneralSettings;
  iot: IotSettings;
  rideSettings: RideSettings;
  batteryCompartmentLocked: boolean;
  testingMode?: boolean;
  mileage?: number | null;
  gear?: string;
  immobilised?: boolean;
  cableLock?: boolean;
  saddleLock?: boolean;
  tailboxLid?: boolean;
  tailboxLock?: boolean;
  factoryData?: {
    cushionStatus?: number;
    topCaseCoverStatus?: number;
    topCaseLockStatus?: number;
  };
  boxClosed?: boolean;
  ignitionOn?: boolean;
  autoLocked?: boolean;
  configHash: string;
  timestamp: string;
};

export type DeviceParams = {
  id: string;
  tenantId: string;
  meta?: Partial<DeviceMeta>;
  state?: Partial<DeviceState>;
};

export class DeviceDocument {
  private _deviceConfigurations: DeviceConfiguration[] | null = null;

  readonly events: DeviceEventDocument[] | null = null;

  @observable paginatedEvents: DeviceEventDocument[] | null = null;

  @observable eventsReady = false;

  @observable deviceConfigurationReady = false;

  id: string;

  tenantId: string;

  @observable meta?: Partial<DeviceMeta>;

  state?: Partial<DeviceState>;

  constructor(device: DeviceParams) {
    this.id = device.id;
    this.tenantId = device.tenantId;
    this.meta = device.meta;
    this.state = device.state;
  }

  @action
  public updateMetaData(data: DeviceMeta): void {
    this.meta = data;
  }

  @action
  public async fetchEvents(
    _startDate: Date,
    _endDate: Date,
    _page: number,
    _pageSize: number
  ): Promise<void> {
    this.eventsReady = false;

    // TODO: get events
    // const offset = page * pageSize;
    // const limit = (page + 1) * pageSize;
    // this.events =
    // after finish set this.eventsReady = true;
  }

  @action
  async updateDeviceConfigRef(ref: string): Promise<void> {
    if (this.meta) {
      this.meta.deviceConfigurationId = ref;
    }
  }

  @action
  async updateDeviceTypeId(deviceTypeId: string): Promise<void> {
    if (this.meta) {
      this.meta.deviceTypeId = deviceTypeId;
    }
  }

  @action
  async removeDevice(): Promise<void> {
    // TODO: remove device
  }

  @action
  async reAddDevice(): Promise<void> {
    // TODO: reactivate device
  }

  get isRemoved(): boolean {
    return !!this.meta?.inactivated;
  }

  @computed
  get deviceConfiguration(): DeviceConfiguration | undefined {
    return this._deviceConfigurations?.find(
      (configDoc) => configDoc.id === this.deviceConfigurationId
    );
  }

  @computed
  get provisioned(): boolean {
    return !!this.meta?.deviceConfigurationId;
  }

  @computed
  get deviceTypeId(): string | undefined {
    const targetDeviceType = DEVICE_TYPES.find((type) => type.id === this.meta?.deviceTypeId);
    if (targetDeviceType) {
      return targetDeviceType.id;
    }
    return this.meta?.deviceTypeId;
  }

  @computed
  get supportedActions(): string[] {
    const deviceType = DEVICE_TYPES.find((type) => type.id === this.deviceTypeId);
    return deviceType?.supportedActions || SUPPORTED_ACTIONS_UNDEFINED_VEHICLE;
  }

  @computed
  get deviceConfigurationId(): string | undefined {
    return this.meta?.deviceConfigurationId;
  }

  @computed
  get firmwareVersion(): string | undefined {
    const { firmware } = this.meta || {};
    if (!firmware) {
      return undefined;
    }
    return `${firmware.iotVersion} / ${firmware.mcuVersion}`;
  }

  @computed
  get lastActiveAt(): Date | undefined {
    return this.meta?.lastActiveAt ? new Date(`${this.meta.lastActiveAt}`) : undefined;
  }

  isInactive(): boolean {
    if (!this.lastActiveAt) {
      return true;
    }

    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
    return this.lastActiveAt.getTime() < twoDaysAgo.getTime();
  }
}
