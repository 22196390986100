import { StatefulTooltip } from 'baseui/tooltip';
import React from 'react';
import { StyleObject } from 'styletron-react';

import { OPEN_LOGS, OPEN_ERROR_LOGS } from '../../e2e/testIds';
import {
  LockClosedOutlineIcon,
  LockOpenOutlineIcon,
  BatteryCompartmentIcon,
  BatteryCompartmentOpenIcon,
  BatteryChargingFullIcon,
  BatteryFullIcon,
  KeyCloseIcon,
  KeyOpenIcon,
  SearchLogsIcon,
  ErrorOutlineIcon,
  CushionLockOpenIcon,
  CushionLockClosedIcon,
  TailboxLockOpenIcon,
  TailboxLockClosedIcon,
  TailboxLidOpenIcon,
  TailboxLidClosedIcon,
  TestingModeIcon,
  NormalModeIcon
} from '../../theme/icons';
import { themedStyled, wunderTheme } from '../../theme/theme';
import { LogType } from '../modal/ModalHelper';

export type DeviceLogsProps = {
  deviceId: string;
  onLogClick: (logData: LogsDataType) => void;
};

export interface LogsDataType {
  deviceId: string;
  logType: LogType;
}

const LogRowEntry = themedStyled('div', () => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
}));

const LogButton = themedStyled(
  'button',
  ({ $theme }): StyleObject => ({
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    marginTop: '-3px', // edge-case
    paddingTop: $theme.sizes.scale100
  })
);

const getTooltipFromExternalLockReason = (externalLockReason: Record<string, unknown>): string => {
  const tooltips: string[] = [];

  Object.keys(externalLockReason).forEach((lockReason) => {
    tooltips.push(`${lockReason} = ${externalLockReason[lockReason] ? 'locked' : 'unlocked'}`);
  });

  return tooltips.join(',\n');
};

interface IconProps {
  iconState: boolean;
  fill?: string;
  title: string;
}

const IconWrapper = themedStyled('div', {
  cursor: 'pointer'
});

const renderIcons = (
  props: IconProps,
  FirstIcon: typeof React.Component | React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  SecondIcon: typeof React.Component | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
) => {
  const { iconState, title, fill } = props;
  const Icon = iconState ? FirstIcon : SecondIcon;

  return (
    <StatefulTooltip content={title} placement="bottom">
      <IconWrapper>
        <Icon
          height={wunderTheme.sizes.scale400}
          width={wunderTheme.sizes.scale400}
          style={{ margin: `0 ${wunderTheme.sizes.scale200} 0 0` }}
          fill={fill}
        />
      </IconWrapper>
    </StatefulTooltip>
  );
};

export const renderBatteryIcon = (charging?: boolean, energyLevel?: number): JSX.Element =>
  renderIcons(
    {
      fill: (energyLevel ?? 0) <= 10 ? wunderTheme.colors.error100 : wunderTheme.colors.text100,
      iconState: !!charging,
      title: charging ? `Battery charging ${energyLevel} %` : `Battery ${energyLevel ? energyLevel : '0'} %`
    },
    BatteryChargingFullIcon,
    BatteryFullIcon
  );

export const renderLockIcon = (locked?: boolean): JSX.Element =>
  renderIcons(
    {
      fill: locked ? wunderTheme.colors.text100 : wunderTheme.colors.error100,
      iconState: !!locked,
      title: locked ? `Locked` : `Unlocked`
    },
    LockClosedOutlineIcon,
    LockOpenOutlineIcon
  );

export const renderCushionStatusIcon = (locked?: number): JSX.Element | null => {
  if (locked === undefined || locked >= 2) {
    return null;
  }

  return renderIcons(
    {
      fill: 'transparent',
      iconState: locked === 0,
      title: locked ? `Open Cushion` : `Closed Cushion`
    },
    CushionLockClosedIcon,
    CushionLockOpenIcon
  );
};

export const renderTopCaseLockStatusIcon = (locked?: number): JSX.Element | null => {
  if (locked === undefined || locked >= 2) {
    return null;
  }

  return renderIcons(
    {
      fill: 'transparent',
      iconState: locked === 0,
      title: locked ? `Open TopCaseLock` : `Closed TopCaseLock`
    },
    TailboxLockClosedIcon,
    TailboxLockOpenIcon
  );
};

export const renderTopCaseCoverStatusIcon = (locked?: number): JSX.Element | null => {
  if (locked === undefined || locked >= 2) {
    return null;
  }

  return renderIcons(
    {
      fill: 'transparent',
      iconState: locked === 0,
      title: locked ? `Open TopCaseLid` : `Closed TopCaseLid`
    },
    TailboxLidClosedIcon,
    TailboxLidOpenIcon
  );
};

export const renderBatteryCompartmentIcon = (batteryCompartmentLocked?: boolean): JSX.Element =>
  renderIcons(
    {
      fill: batteryCompartmentLocked ? wunderTheme.colors.text100 : wunderTheme.colors.error100,
      iconState: !!batteryCompartmentLocked,
      title: batteryCompartmentLocked
        ? `Battery compartment locked`
        : `Battery compartment unlocked`
    },
    BatteryCompartmentIcon,
    BatteryCompartmentOpenIcon
  );

export const renderExternalLock = (
  lockLocked?: boolean,
  lockReason?: Record<string, unknown>
): JSX.Element | null => {
  if (lockLocked === undefined || lockReason === undefined) {
    return null;
  }

  return renderIcons(
    {
      iconState: lockLocked,
      title: getTooltipFromExternalLockReason(lockReason)
    },
    KeyOpenIcon,
    KeyCloseIcon
  );
};

export const renderLogRowEntry = (
  value: DeviceLogsProps | undefined,
  logType: LogType
): JSX.Element | null => {
  if (!value) return null;

  const { deviceId, onLogClick } = value;

  return (
    <LogRowEntry>
      <LogButton
        data-test-id={`${logType === 'logs' ? OPEN_LOGS : OPEN_ERROR_LOGS}-${deviceId}`}
        onClick={() => onLogClick({ deviceId, logType })}
      >
        {renderIcons(
          {
            fill: 'transparent',
            iconState: logType === 'logs',
            title: logType === 'logs' ? `Device Logs` : `Device Errors`
          },
          SearchLogsIcon,
          ErrorOutlineIcon
        )}
      </LogButton>
    </LogRowEntry>
  );
};

export const renderVehicleModeIcon = (isTestingMode?: boolean): JSX.Element =>
  renderIcons(
    {
      fill: isTestingMode ? wunderTheme.colors.warning100 : wunderTheme.colors.text100,
      iconState: !!isTestingMode,
      title: isTestingMode ? `Testing mode` : `Normal mode`
    },
    TestingModeIcon,
    NormalModeIcon
  );
