import { makeAutoObservable, runInAction } from 'mobx';

import { AuthClient } from '../client/interfaces';
import { UserDocument } from '../models/userDocument';
import { TokenHandler } from '../utils/tokenHandler';

export class UserStore {
  userIdToken: string | null = null;

  currentUser: UserDocument | null = null;

  loginError: string | null = null;

  constructor(readonly authClient: AuthClient, readonly tokenHandler: TokenHandler) {
    makeAutoObservable(this, {
      authClient: false,
      tokenHandler: false
    });
  }

  async loginUser(email: string, password: string): Promise<void> {
    this.loginError = null;

    try {
      const { success, token } = await this.authClient.authenticate(email, password);
      if (success && token) {
        const user = await this.authClient.getCurrentUser();
        if (user) {
          runInAction(() => {
            this.loginError = null;
            this.currentUser = new UserDocument(user);
          });
          this.userIdToken = await this.tokenHandler.getToken();
        }
      }
    } catch (err) {
      runInAction(() => {
        this.loginError = err.code === 'auth/too-many-requests'
          ? 'Too many unsuccessful login attempts. Please try again later.'
          : 'The email address or password you entered is invalid.';
      });
    }
  }

  async fetchCurrentUser(): Promise<void> {
    const token = this.tokenHandler.retrieveToken();
    if (token !== '' && this.currentUser === null) {
      const user = await this.authClient.getCurrentUser();
      
      runInAction(() => {
        if (user) {
          this.currentUser = new UserDocument(user);
        } else {
          this.currentUser = null;
        }
      });

      if (user) {
        this.userIdToken = await this.tokenHandler.getToken();
      } else {
        await this.tokenHandler.signOut();
      }
    }
  }

  async removeCurrentUser(): Promise<void> {
    runInAction(() => {
      this.currentUser = null;
    });
    await this.tokenHandler.signOut();
  }

  resetLoginError(): void {
    this.loginError = null;
  }
}
