import { Unstable_DataTable as DataTable } from 'baseui/data-table';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { DEVICE_TYPES, UNDEFINED_VEHICLE_TYPE } from '@wunder/tools-iot-connector-device-types';

import { useStores } from '../../hooks/use-stores';
import { DeviceState, DeviceDocument } from '../../models';
import { SortColumn, SortDirection } from '../../stores';
import { formatDateForTableEntries } from '../../utils/date';
import { DeviceLogModal } from '../deviceLogModal/DeviceLogModal';
import { DeviceLogModalError } from '../deviceLogModal/DeviceLogModalError';

import {
  columns,
  CoordinateProps,
  DeviceStatusProps,
  resolveNextSortDirection,
  RowDataT,
  sortColumnToTableColumnIndex,
  sortDirectionToTableSortDirection,
  tableColumnToSortColumnIndex
} from './DeviceListTableHelper';
import { LogsDataType } from './DeviceListTableIcons';

const NAV_HEIGHT = 64;
const HEALTH_STATUS_HEIGHT = 82;

function formatLatAndLon(state: Partial<DeviceState>): CoordinateProps | undefined {
  const { latitude, longitude } = state;

  if (latitude && longitude) {
    return { latitude, longitude };
  }

  return undefined;
}

function formatStatusValues(
  deviceId: string,
  state: Partial<DeviceState>,
  provisioned = false
): DeviceStatusProps | undefined {
  if (Object.keys(state).length === 0) {
    return undefined;
  }

  const { energyLevel, factoryData, batteryCompartmentLocked, charging, locked, testingMode } =
    state;

  return {
    deviceId,
    energyLevel: energyLevel || undefined,
    factoryData,
    isBatteryCompartmentLocked: batteryCompartmentLocked,
    isCharging: charging,
    isLocked: locked,
    provisioned,
    isTestingMode: testingMode
  };
}

interface TableRow {
  id: string;
  data: RowDataT;
}

export const DeviceListTable = observer(function DeviceListTable({
  devices,
  initialSelectedRows,
  onSelectionChange,
  onSortChange,
  sortColumn,
  sortDirection
}: {
  devices: DeviceDocument[] | undefined;
  initialSelectedRows?: Set<string>;
  onSelectionChange?: (selection: { id: string }[]) => void;
  onSortChange?: (sortColumn: SortColumn, sortDirection: SortDirection) => void;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}): JSX.Element | null {
  const [selectedRowIds, setSelectedRowIds] = React.useState<Set<string>>(
    initialSelectedRows || new Set()
  );
  const { deviceLogStore, deviceConfigurationStore } = useStores();
  const [showLogsForDevice, setShowLogsForDevice] = React.useState<LogsDataType>({
    deviceId: '',
    logType: 'logs'
  });

  const rows: TableRow[] = (devices || []).map((device): TableRow => {
    const deviceTypeName =
      DEVICE_TYPES.find((deviceType) => deviceType.id === device.deviceTypeId)?.name ||
      UNDEFINED_VEHICLE_TYPE.name;

    let deviceConfiguration;
    if (device.meta?.deviceConfigurationId) {
      deviceConfiguration = deviceConfigurationStore.getDeviceConfiguration(
        device.meta.deviceConfigurationId
      );
    }

    const id = device.id;
    const state: Partial<DeviceState> = device?.state || {};

    return {
      id,
      data: [
        id,
        deviceTypeName,
        deviceConfiguration?.name,
        formatLatAndLon(state),
        formatDateForTableEntries(device.lastActiveAt),
        formatStatusValues(id, state, !!deviceConfiguration),
        device.meta?.firmware?.iotVersion,
        {
          deviceId: id,
          onLogClick: ({ deviceId, logType }: LogsDataType) =>
            setShowLogsForDevice({ deviceId, logType })
        },
        {
          deviceId: id,
          onLogClick: ({ deviceId, logType }: LogsDataType) =>
            setShowLogsForDevice({ deviceId, logType })
        }
      ]
    };
  });

  function handleSelectChange(next: Set<string>): void {
    setSelectedRowIds(next);

    const selectionCallback = onSelectionChange;
    if (selectionCallback) {
      selectionCallback(rows.filter((r) => next.has(r.id)));
    }
  }

  return (
    <div style={{ height: `calc(100% - ${NAV_HEIGHT + HEALTH_STATUS_HEIGHT}px)`, width: '100%' }}>
      {devices && (
        <DataTable
          batchActions={[{}]}
          filterable={false}
          searchable={false}
          columns={columns}
          rows={rows}
          selectedRowIds={selectedRowIds}
          onSort={(columnIndex: number) => {
            const nextSortColumn = tableColumnToSortColumnIndex(columnIndex);
            const nextSortDirection = resolveNextSortDirection(
              nextSortColumn,
              sortColumn,
              sortDirection
            );
            onSortChange?.(nextSortColumn, nextSortDirection);
          }}
          sortIndex={sortColumnToTableColumnIndex(sortColumn)}
          sortDirection={sortDirectionToTableSortDirection(sortDirection)}
          onSelectMany={(incomingRows: { id: string }[]): void => {
            // only adds rows that are visible in the table
            handleSelectChange(new Set([...selectedRowIds, ...incomingRows.map((r) => r.id)]));
          }}
          onSelectNone={(): void => {
            handleSelectChange(new Set());
          }}
          onSelectOne={(row: { id: string }): void => {
            if (selectedRowIds.has(row.id)) {
              selectedRowIds.delete(row.id);
            } else {
              selectedRowIds.add(row.id);
            }
            handleSelectChange(new Set(selectedRowIds));
          }}
          onSelectNode={(): void => {
            setSelectedRowIds(new Set());
          }}
        />
      )}

      <DeviceLogModal
        deviceId={showLogsForDevice.logType === 'logs' ? showLogsForDevice.deviceId : ''}
        isOpen={showLogsForDevice.deviceId !== '' && showLogsForDevice.logType === 'logs'}
        onClose={() => {
          setShowLogsForDevice({ deviceId: '', logType: 'logs' });
          deviceLogStore.resetData();
        }}
      />

      <DeviceLogModalError
        deviceId={showLogsForDevice.logType === 'errors' ? showLogsForDevice.deviceId : ''}
        isOpen={showLogsForDevice.deviceId !== '' && showLogsForDevice.logType === 'errors'}
        onClose={() => {
          setShowLogsForDevice({ deviceId: '', logType: 'errors' });
          deviceLogStore.resetData();
        }}
      />
    </div>
  );
});
