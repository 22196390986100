import { Grid, Cell } from 'baseui/layout-grid';
import { Spinner } from 'baseui/spinner';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import { useStores } from '../../hooks/use-stores';
import { FilterTypes } from '../../stores/deviceStore';
import { themedUseStyletron as useStyletron, themedStyled } from '../../theme/theme';
import { TileType } from '../../typings/tile';
import { DeviceListHeader } from '../deviceListHeader';
import { DeviceListTable } from '../deviceListTable';
import { SyncStatusPanel } from '../syncStatus/syncStatusPanel';

import { DeviceListFooter } from './DeviceListFooter';

const HEADER_HEIGHT = 64;

const SpinnerContainer = themedStyled('div', () => ({
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)'
}));

export const DeviceList = function DeviceList(): JSX.Element | null {
  const { deviceStore, tenantStore, deviceConfigurationStore, userStore } = useStores();
  const [selected, setSelected] = React.useState<string[] | undefined>();
  const [, theme] = useStyletron();

  React.useEffect(() => {
    const interval = window.setInterval(() => {
      deviceStore.refresh();
    }, 1000 * 10);
    return () => window.clearInterval(interval);
  }, []);

  return useObserver(() => {
    if (!tenantStore.currentTenant || !userStore.currentUser) {
      return null;
    }

    deviceConfigurationStore.initData(tenantStore.currentTenant.id as string);

    if (deviceConfigurationStore.ready) {
      deviceStore.initData(tenantStore.currentTenant.id as string);
    }

    const applyPanelFilter = (currentTile: TileType): void => {
      deviceStore.setSelectedTile(currentTile);

      switch (currentTile) {
        case TileType.Total:
          deviceStore.removeAllFilter();
          break;

        case TileType.WithConfig:
          deviceStore.replaceFilters([
            {
              type: FilterTypes.WithConfig
            }
          ]);
          break;

        case TileType.WithoutConfig:
          deviceStore.replaceFilters([
            {
              type: FilterTypes.WithoutConfig
            }
          ]);
          break;

        case TileType.LongInactiveDevices:
          deviceStore.replaceFilters([
            {
              type: FilterTypes.LongInactiveDevices
            }
          ]);
          break;

        case TileType.ShortTermInactiveDevices:
          deviceStore.replaceFilters([
            {
              type: FilterTypes.ShortTermInactiveDevices
            }
          ]);
          break;

        default:
          break;
      }
    };

    if (deviceStore.isTotalTile) {
      deviceStore.setSelectedTile(TileType.Total);
    }

    return (
      <Grid gridUnit='rem' gridMargins={[1, 1, 1]} gridGutters={[1, 1, 1]}>
        <Cell span={[0, 0, 0]} />
        <Cell span={[4, 8, 12]}>
          <div
            style={{
              height: window.innerHeight - HEADER_HEIGHT * 3,
              marginTop: theme.sizes.scale300,
              width: 'auto'
            }}
          >
            <SyncStatusPanel
              devicesWithConfig={deviceStore.totalDevicesWithConfig}
              devicesWithOutConfig={deviceStore.totalDevicesWithoutConfig}
              longInactiveDevices={deviceStore.longInactiveDevices}
              shortTermInactiveDevices={deviceStore.shortTermInactiveDevices}
              applyFilter={applyPanelFilter}
              selectedTile={deviceStore.selectedTile}
            />

            <DeviceListHeader
              selected={selected}
              onClearSelection={() => {
                setSelected(undefined);
              }}
            />

            {deviceStore.devicesReady ? (
              <>
                <DeviceListTable
                  devices={deviceStore.devicesToRender}
                  sortColumn={deviceStore.sortColumn}
                  sortDirection={deviceStore.sortDirection}
                  onSortChange={deviceStore.sortDevices}
                  onSelectionChange={(selection: { id: string }[]): void => {
                    setSelected(selection.length ? selection.map((item) => item.id) : undefined);
                  }}
                />
                <DeviceListFooter
                  onPrev={(): void => setSelected(undefined)}
                  onNext={(): void => setSelected(undefined)}
                  onSizeChange={(value: number): void => {
                    deviceStore.setPaginationSize(value);
                    setSelected(undefined);
                  }}
                  deviceStore={deviceStore}
                />
              </>
            ) : (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            )}
          </div>
        </Cell>
        <Cell span={[0, 0, 0]} />
      </Grid>
    );
  });
};
