import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleObject } from 'styletron-react';



import loginImage from '../../assets/images/login.jpg';
import { SIGN_IN } from '../../e2e/testIds';
import { useStores } from '../../hooks/use-stores';
import { WunderIotConnector, WunderLogoBlackIcon } from '../../theme/icons';
import { themedUseStyletron as useStyletron, themedStyled } from '../../theme/theme';

const LoginContainer = themedStyled('div', () => ({
  display: 'flex',
  height: '100vh'
}));

const LoginInformation = themedStyled(
  'div',
  ({ $theme }): StyleObject => ({
    alignItems: 'center',
    backgroundColor: $theme.colors.white,
    display: 'flex',
    justifyContent: 'center',
    marginTop: `-${$theme.sizes.scale550}`,
    width: '50%'
  })
);

const LoginForm = themedStyled(
  'div',
  ({ $theme }): StyleObject => ({
    margin: $theme.sizes.scale300,
    width: '380px'
  })
);

const LoginHeadline = themedStyled(
  'h1',
  ({ $theme }): StyleObject => ({
    ...$theme.typography.headingSmallRegular,
    marginBottom: $theme.sizes.scale400,
    marginTop: $theme.sizes.scale500
  })
);

const LoginImage = themedStyled('img', () => ({
  objectFit: 'cover',
  width: '50%'
}));

const WunderIotConnectorLogo = themedStyled(
  WunderIotConnector,
  ({ $theme }): StyleObject => ({
    height: $theme.sizes.scale550,
    width: '330px'
  })
);

const WunderLogo = themedStyled(
  WunderLogoBlackIcon,
  ({ $theme }): StyleObject => ({
    height: $theme.sizes.scale650,
    width: $theme.sizes.scale650,
    position: 'fixed',
    left: '0px',
    top: '0px'
  })
);

export const Login = observer(function Login(): JSX.Element | null {
  const [, theme] = useStyletron();
  const { userStore } = useStores();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(e.currentTarget.value);
    userStore.resetLoginError();
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(e.currentTarget.value);
    userStore.resetLoginError();
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    userStore.loginUser(email, password);
  };

  return (
      <LoginContainer>
        <LoginInformation>
          <WunderLogo />
          <LoginForm>
            <WunderIotConnectorLogo />
            <LoginHeadline>{t('loginPage.headline')}</LoginHeadline>
            <form onSubmit={onSubmit}>
              <FormControl label={() => 'Email'}>
                <Input
                  id='email'
                  value={email}
                  onChange={onChangeEmail}
                  error={Boolean(userStore.loginError)}
                  required
                />
              </FormControl>
              <FormControl label={() => 'Password'} error={userStore.loginError}>
                <Input
                  id='password'
                  type='password'
                  value={password}
                  onChange={onChangePassword}
                  error={Boolean(userStore.loginError)}
                  required
                />
              </FormControl>
              <Button
                type='submit'
                overrides={{
                  BaseButton: {
                    style: {
                      height: theme.sizes.scale600
                    },
                    props: {
                      'data-test-id': SIGN_IN
                    }
                  }
                }}
              >
                Sign in
              </Button>
            </form>
          </LoginForm>
        </LoginInformation>
        <LoginImage src={loginImage} />
      </LoginContainer>
  );
});
