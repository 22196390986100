import { makeAutoObservable, runInAction } from 'mobx';

import { FetchDocumentationsResponse, IOTClient } from '../client/interfaces';

export class FeatureStore {
  activeFeatures: Record<string, unknown> = {};

  documentations: FetchDocumentationsResponse | undefined;

  constructor(readonly iotClient: IOTClient, features: Record<string, unknown>) {
    makeAutoObservable(this, {
      iotClient: false
    });
    this.activeFeatures = features;
  }

  isActive(featureName: string): boolean {
    return !!this.activeFeatures[featureName];
  }

  async getDocumentation(tenantId: string): Promise<FetchDocumentationsResponse> {
    const docs = await this.iotClient.fetchDocumentations(tenantId);
    runInAction(() => {
      this.documentations = docs;
    });
    return docs;
  }
}
