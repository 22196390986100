import React from 'react';

import {
  DEVICE_TYPES as allSupportedDeviceTypes,
  DeviceCommandInstruction
} from '@wunder/tools-iot-connector-device-types';

import {
  GENERAL_COMMANDS,
  LOCK_COMMANDS,
  SET_VEHICLE_TYPE,
  CHANGE_DEVICE_CONFIGURATION
} from '../../e2e/testIds';
import { useStores } from '../../hooks/use-stores';
import { DeviceDocument } from '../../models';
import { SettingsIcon, LockClosedIcon, WidgetIcon, ScooterIcon } from '../../theme/icons';

import {
  DeviceListBatchAction,
  DeviceListBatchActionId,
  sendGeneralCommandActionListItems,
  sendLockCommandActionListItems
} from './DeviceListActions';
import {
  DeviceListBatchActionContainer,
  DeviceBatchButtonList,
  DeviceBatchButtonListOption
} from './DeviceListBatchActions';
import { DeviceListCopyIMEI } from './DeviceListCopyIMEI';
import { DeviceListRemoveDevices } from './DeviceListRemoveDevices';

export interface DeviceWithSupportedAction {
  deviceId: string;
  supportedActions: string[];
}

export const DeviceListBar = ({
  selected,
  selectedDevices,
  handleBatchSendCommands,
  openModal
}: {
  selected: string[];
  selectedDevices: DeviceDocument[];
  handleBatchSendCommands: (
    devices: string[],
    command: DeviceCommandInstruction,
    payload?: Record<string, unknown>
  ) => void;
  openModal: (instruction: DeviceListBatchActionId) => void;
}): JSX.Element => {
  const { featureStore, deviceStore, deviceConfigurationStore, userStore } = useStores();
  const { currentUser } = userStore;
  const hasAdminAccess = currentUser?.hasAdminAccessOnSelectedTenant();

  const handleDeviceConfigUpdate = async (
    devices: string[],
    deviceConfigId: string
  ): Promise<void> => {
    await deviceConfigurationStore.setConfigurationOnDevices(deviceConfigId, devices);
    devices.forEach((deviceId) => {
      const currentDevice = deviceStore.getDevice(deviceId);
      currentDevice?.updateDeviceConfigRef(deviceConfigId);
    });
  };

  const handleBatchChangeType = (devices: string[], deviceTypeId: string): void => {
    devices.forEach((deviceId) => {
      deviceStore.updateDeviceTypeId(deviceId, deviceTypeId);
    });
  };

  const isOptionDisabled = (itemId: string) => {
    const selectedDevicesFilteredByItemId = selectedDevices.filter((entry) =>
      entry.supportedActions.includes(itemId)
    );
    // only display actions, that are supported by all selected devices
    return selectedDevicesFilteredByItemId.length !== selectedDevices.length;
  };

  return (
    <DeviceListBatchActionContainer>
      {hasAdminAccess && (
        <DeviceListRemoveDevices
          devices={selectedDevices}
          onClicked={() => {
            openModal(DeviceListBatchAction.remove);
          }}
        />
      )}
      <DeviceListCopyIMEI devices={selectedDevices} />
      {featureStore.isActive('changeDeviceType') && hasAdminAccess && (
        <DeviceBatchButtonList
          label='Set Vehicle Model'
          testId={SET_VEHICLE_TYPE}
          icon={ScooterIcon}
          items={allSupportedDeviceTypes}
          onItemSelect={({ item }: { item: DeviceBatchButtonListOption }): void => {
            const devices = selected;

            if (devices) {
              handleBatchChangeType(devices, item.id);
            }
          }}
        />
      )}
      <DeviceBatchButtonList
        label='General Commands'
        testId={GENERAL_COMMANDS}
        icon={WidgetIcon}
        items={sendGeneralCommandActionListItems}
        isOptionDisabled={isOptionDisabled}
        onItemSelect={({ item }: { item: DeviceBatchButtonListOption }): void => {
          const devices = selected;
          const commandItem = sendGeneralCommandActionListItems.find(
            (listItem) => listItem.id === item.id
          );

          if (
            [
              DeviceCommandInstruction.changePassword,
              DeviceCommandInstruction.firmwareUpdate
            ].includes(item.id)
          ) {
            openModal(item.id);
          } else if (devices && commandItem) {
            handleBatchSendCommands(devices, commandItem.command);
          }
        }}
      />
      <DeviceBatchButtonList
        label='Lock Commands'
        testId={LOCK_COMMANDS}
        icon={LockClosedIcon}
        items={sendLockCommandActionListItems}
        isOptionDisabled={isOptionDisabled}
        onItemSelect={({ item }: { item: DeviceBatchButtonListOption }): void => {
          const devices = selected;
          const commandItem = sendLockCommandActionListItems.find(
            (listItem) => listItem.id === item.id
          );

          if (devices && commandItem) {
            handleBatchSendCommands(devices, commandItem.command);
          }
        }}
      />
      <DeviceBatchButtonList
        label='Change Configuration'
        testId={CHANGE_DEVICE_CONFIGURATION}
        icon={SettingsIcon}
        last
        items={(deviceConfigurationStore.deviceConfigurations || []).map((config) => ({
          id: config.id || '',
          name: config.name,
          value: config.deviceTypeId
        }))}
        onItemSelect={({ item }: { item: DeviceBatchButtonListOption }): void => {
          const devices = selected;
          if (devices) {
            handleDeviceConfigUpdate(devices, item.id);
          }
        }}
        isOptionDisabled={(_itemId: string, value?: string) =>
          selectedDevices.some(
            (device) => device.deviceTypeId !== undefined && device.deviceTypeId !== value
          )
        }
      />
    </DeviceListBatchActionContainer>
  );
};
