import {
  createTheme,
  createThemedStyled,
  createThemedWithStyle,
  createThemedUseStyletron,
  lightThemePrimitives
} from 'baseui';
import { ElementType } from 'react';
import { StyletronComponent, StyleObject } from 'styletron-react';

import './typography.css';
import { themeColors } from './themeColors';
import { themeShadows } from './themeShadows';
import { themeSizes } from './themeSizes';
import { themeTypography } from './themeTypography';

// from https://github.com/uber/baseweb/issues/2640
declare module 'baseui' {
  export interface ThemedStyleFn<Theme> {
    <
      C extends keyof JSX.IntrinsicElements | React.ComponentType<unknown>,
      P extends Record<string, unknown>,
      T = Theme
    >(
      component: C,
      style: (props: P & { $theme: T }) => StyleObject
    ): StyletronComponent<
      C,
      Pick<React.ComponentProps<C>, Exclude<keyof React.ComponentProps<C>, 'className'>> &
        Omit<P, '$theme'>
    >;

    <C extends keyof JSX.IntrinsicElements | React.ComponentType<unknown>>(
      component: C,
      style: StyleObject
    ): StyletronComponent<
      C,
      Pick<React.ComponentProps<C>, Exclude<keyof React.ComponentProps<C>, 'className'>>
    >;
  }

  export interface WithThemedStyleFn<Theme> {
    <
      C extends StyletronComponent<ElementType<unknown>, {}>,
      P extends Record<string, unknown>,
      T = Theme
    >(
      component: C,
      style: (props: P & { $theme: T }) => StyleObject
    ): StyletronComponent<C, React.ComponentProps<C> & P>;
    <C extends StyletronComponent<ElementType<unknown>, {}>>(
      component: C,
      style: StyleObject
    ): StyletronComponent<C, React.ComponentProps<C>>;
  }
}

const primitives = {
  ...lightThemePrimitives
};

export const overrides = {
  colors: {
    ...themeColors,

    // buttons
    buttonPrimaryActive: themeColors.primary200,
    buttonPrimaryFill: themeColors.primary100,
    buttonPrimaryHover: themeColors.primary200,
    buttonPrimaryText: themeColors.textWhite,

    buttonPrimarySelectedFill: themeColors.primary200,
    buttonPrimarySelectedText: themeColors.textWhite,
    buttonPrimarySpinnerForeground: themeColors.textWhite,
    buttonPrimarySpinnerBackground: themeColors.bg50,

    buttonSecondaryFill: themeColors.primary10,
    buttonSecondaryText: themeColors.primary100,
    buttonSecondaryHover: themeColors.primary10,
    buttonSecondaryActive: themeColors.primary100,
    buttonSecondarySelectedFill: themeColors.primary100,
    buttonSecondarySelectedText: themeColors.primary200,
    buttonSecondarySpinnerForeground: themeColors.textWhite,
    buttonSecondarySpinnerBackground: themeColors.bg50,

    // inputs
    borderFocus: themeColors.primary100,

    // tick (also applies to: radio-button and checkbox)
    tickFillSelected: themeColors.primary100,
    tickFillSelectedHover: themeColors.primary100,
    tickFillSelectedHoverActive: themeColors.primary100,
    tickFillHover: themeColors.bg10,
    tickBorder: themeColors.bg25,

    contentSecondary: themeColors.textInactive
  },

  typography: {
    ...themeTypography,

    LabelSmall: {
      ...themeTypography.labelCaptionRegular
    },
    LabelMedium: {
      ...themeTypography.contentBodyRegular
    },
    ParagraphMedium: {
      ...themeTypography.contentBodyRegular
    },
    font100: themeTypography.labelCaptionRegular
  },

  shadows: {
    ...themeShadows
  },

  sizes: themeSizes
};

export const wunderTheme = createTheme({ ...primitives, ...overrides});
export const themedStyled = createThemedStyled<typeof wunderTheme>();
export const themedWithStyle = createThemedWithStyle<typeof wunderTheme>(); // for styled-components use themedWithStyle
export const themedUseStyletron = createThemedUseStyletron<typeof wunderTheme>();
export type WunderTheme = typeof wunderTheme;
