import { makeAutoObservable } from 'mobx';

export class UiStore {
  isSidebarOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  updateIsSidebarOpenValue = (isSidebarOpen: boolean): void => {
    this.isSidebarOpen = isSidebarOpen;
  };
}
