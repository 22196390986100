import React from 'react';
import { StyleObject } from 'styletron-react';

import { themedStyled } from '../../theme/theme';

const OuterContainer = themedStyled('span', () => ({
  alignItems: 'flex-start',
  display: 'flex',
  justifyContent: 'space-between'
}));

const RightContainer = themedStyled('div', () => ({
  alignItems: 'flex-start',
  display: 'flex'
}));

const Headline = themedStyled(
  'span',
  ({ $theme }): StyleObject => ({
    ...$theme.typography.headingSmallRegular,
    marginTop: $theme.sizes.scale200,
    whiteSpace: 'nowrap'
  })
);

type DeviceListBarFilterProps = {
  children: React.ReactNode;
  headline: string;
};

export const DeviceListBarFilter = ({
  headline,
  children
}: DeviceListBarFilterProps): JSX.Element => (
  <OuterContainer>
    <Headline>{headline}</Headline>
    <RightContainer>{children}</RightContainer>
  </OuterContainer>
);
