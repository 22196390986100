import { Drawer, DrawerProps, ANCHOR } from 'baseui/drawer';
import React from 'react';
import { StyleObject } from 'styletron-react';

import { themedUseStyletron as useStyletron } from '../../theme/theme';

interface SidebarProps extends DrawerProps {
  children?: React.ReactNode;
}

export const Sidebar = ({ children, ...others }: SidebarProps): JSX.Element => {
  const [, theme] = useStyletron();

  return (
    <Drawer
      {...others}
      anchor={ANCHOR.right}
      overrides={{
        DrawerBody: {
          style: (): StyleObject => ({
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0
          })
        },
        DrawerContainer: {
          style: (): StyleObject => ({
            backgroundColor: theme.colors.bg10,
            transitionDuration: '200ms'
          })
        },
        Close: {
          style: (): StyleObject => ({
            display: 'none'
          })
        }
      }}
    >
      {children}
    </Drawer>
  );
};
