import { subMinutes } from 'date-fns';
import { makeAutoObservable, runInAction } from 'mobx';

import { IOTClient } from '../client/interfaces';

export interface DeviceLogType {
  message: string;
  type: 0 | 1;
  timestamp: string;
}

export enum LoadingType {
  INITIAL = 'initial',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error'
}

export interface DeviceFetchOptions {
  tenantId: string;
  deviceId: string;
  dates: Date[];
  page?: number;
  limit?: number;
}

export class DeviceLogStore {
  data: DeviceLogType[] | null = null;

  loadingState: LoadingType = LoadingType.INITIAL;

  constructor(readonly iotClient: IOTClient) {
    makeAutoObservable(this, {
      iotClient: false
    });
  }

  get dataTableEntries(): string[][] {
    if (this.data && this.data.length > 0) {
      return this.data
        ?.slice().reverse()
        .map((entry) => [
          entry.timestamp,
          entry.type === 0 ? 'incoming' : 'outgoing',
          entry.message
        ]);
    }
    return [];
  }

  async fetchDeviceLog({
    tenantId,
    deviceId,
    dates,
    limit,
    page
  }: DeviceFetchOptions): Promise<void> {
    runInAction(() => {
      this.data = null;
      this.loadingState = LoadingType.LOADING;
    });

    const startDate = subMinutes(dates[0], dates[0].getTimezoneOffset()).toISOString();
    const endDate = subMinutes(dates[1], dates[1].getTimezoneOffset()).toISOString();
    
    try {
      const response = await this.iotClient.fetchDeviceLogs(
        tenantId,
        deviceId,
        startDate,
        endDate,
        page,
        limit
      );

      runInAction(() => {
        if (response.success && response.logs) {
          this.data = response.logs;
          this.loadingState = LoadingType.SUCCESS;
        } else {
          this.loadingState = LoadingType.ERROR;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.loadingState = LoadingType.ERROR;
      });
    }
  }

  resetData(): void {
    runInAction(() => {
      this.data = null;
      this.loadingState = LoadingType.INITIAL;
    });
  }
}
