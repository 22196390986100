import { useStyletron } from 'baseui';
import { Delete } from 'baseui/icon';
import React from 'react';

import { WarningIcon } from '../../theme/icons';
import { themedStyled, WunderTheme } from '../../theme/theme';

export interface AlertProps {
  open?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

export const Alert: React.FC<AlertProps> = (props) => {
  const { children, open, onClose } = props;

  if (!open) {
    return null;
  }

  const [css] = useStyletron();

  const AlertContainer = themedStyled('div', ({ $theme }: { $theme: WunderTheme }) => ({
    ...$theme.typography.labelCaptionRegular,
    alignItems: 'center',
    background: '#FFF2E6',
    boxShadow: '0px 2px 8px rgba(14, 20, 26, 0.12)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flext-start',
    lineHeight: '24px',
    marginBottom: '16px',
    padding: '12px 16px',
  }));

  const CloseButton = themedStyled('button', () => ({
    backgroundColor: `transparent`,
    border: `none`,
    cursor: 'pointer',
    display: `inline`,
    lineHeight: '6px',
    marginLeft: 'auto',
    padding: 0,
  }));

  return (
    <>
      <AlertContainer>
        <div className={css({ marginRight: '16px' })}>
          <WarningIcon width="24px" height="24px" />
        </div>
        <div className={css({ maxWidth: 'calc(100% - 112px)' })}>{children}</div>
        <CloseButton onClick={onClose}>
          <Delete size="32px" />
        </CloseButton>
      </AlertContainer>
    </>
  );
};
