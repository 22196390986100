import { Input } from 'baseui/input';
import { Modal, ModalBody, ModalButton } from 'baseui/modal';
import React, { useState } from 'react';

import { themedUseStyletron as useStyletron, wunderTheme } from '../../theme/theme';
import { StyledModalHeader, BodyText, ModalFooter } from '../modal/ModalHelper';

interface ConfirmationModalProps {
  isOpen?: boolean;
  validateInput?: (inputText: string) => boolean;
  title: string;
  children: React.ReactNode;
  placeholder?: string;
  primaryButtonText: string;
  primaryAction: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    inputText: string,
  ) => Promise<void> | void;
  secondaryButtonText: string;
  onClose: () => void;
}

export const ConfirmationModal = ({
  isOpen,
  validateInput,
  title,
  children,
  placeholder,
  primaryButtonText,
  primaryAction,
  secondaryButtonText,
  onClose,
}: ConfirmationModalProps): JSX.Element => {
  const [, theme] = useStyletron();
  const [inputText, setInputText] = useState('');
  const [loading, setLoading] = useState(false);

  const onConfirm = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    setLoading(true);
    await primaryAction(event, inputText);
    setInputText('');
    setLoading(false);
  };

  const onCancel = (): void => {
    onClose();
    setInputText('');
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      overrides={{
        Close: {
          style: {
            display: 'none',
          },
        },
        Dialog: {
          style: {
            width: 'auto',
          },
        },
      }}
    >
      <StyledModalHeader>{title}</StyledModalHeader>
      <ModalBody>
        <BodyText>{children}</BodyText>
        <Input
          overrides={{
            Root: {
              style: {
                marginTop: wunderTheme.sizes.scale200,
              },
            },
          }}
          value={inputText}
          onChange={(event) => setInputText(event.currentTarget.value)}
          placeholder={placeholder}
        />
      </ModalBody>

      <ModalFooter>
        <ModalButton
          kind="secondary"
          overrides={{
            BaseButton: {
              style: {
                height: theme.sizes.scale600,
              },
            },
          }}
          onClick={onCancel}>
          {secondaryButtonText}
        </ModalButton>
        <ModalButton
          onClick={onConfirm}
          disabled={(validateInput && !validateInput(inputText)) || loading}
          isLoading={loading}
          overrides={{
            BaseButton: {
              style: {
                height: theme.sizes.scale600,
              },
            },
          }}
        >
          {primaryButtonText}
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};
