/* eslint-disable @typescript-eslint/no-explicit-any */
//
// Taken from: https://usehooks.com/useHover/
// Enables descendant Hover with Styletron: https://www.styletron.org/concepts/#descendant-hover
//
import { noop } from 'lodash';
import { useState, useEffect, useRef } from 'react';

export const useHover = (): any => {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current as any;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
      return noop;
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
};
