import React from 'react';

import { Radio, RadioGroup } from '../radio/Radio';

export const CustomRadioGroup = ({
  value,
  name,
  onChange,
  options
}: {
  value: string;
  name: string;
  onChange: (value: unknown) => void;
  options: { value: number; name: string }[];
}): JSX.Element => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.persist();

    const { value: eventValue } = event.target as HTMLInputElement;
    const selectedOption = options.find((option) => option.value.toString() === eventValue);

    if (selectedOption) {
      onChange(selectedOption.value);
    }
  };

  return (
    <RadioGroup align='horizontal' name={name} onChange={handleChange} value={value.toString()}>
      {options.map((option) => (
        <Radio key={option.value} value={option.value.toString()}>
          {option.name}
        </Radio>
      ))}
    </RadioGroup>
  );
};
