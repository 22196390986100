/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormField, TabbedFormField, FieldType } from '../formHelper/formFieldTypes';

export const generalSettingsFields: FormField[] = [
  {
    name: 'name',
    translationKey: 'tenant.settings.name',
    type: 'text'
  },
  {
    name: 'devicePassword',
    translationKey: 'tenant.settings.devicePassword',
    type: 'text'
  },
  {
    name: 'deviceUpdateTimeout',
    translationKey: 'tenant.settings.deviceUpdateTimeout',
    type: 'number',
    options: {
      min: 25,
      max: 120,
      step: 1
    }
  },
  {
    name: 'enableAutomaticDeviceCreation',
    translationKey: 'tenant.settings.enableAutomaticDeviceCreation',
    type: 'switch'
  }
];

export const gatewayFields: FormField[] = [
  {
    name: 'endpoint.address',
    translationKey: 'tenant.settings.address',
    type: 'disabled'
  },
  {
    name: 'endpoint.port',
    translationKey: 'tenant.settings.port',
    type: 'disabled'
  }
];

export const credentialFields = (isAdmin: boolean): FormField[] => {
  const baseUrlType: FieldType = isAdmin ? 'text' : 'disabled';

  return [
    {
      label: 'Wunder Tools IoT Connector',
      name: 'iotConnector',
      type: 'group',
      fields: [
        {
          name: 'baseUrl',
          translationKey: 'tenant.settings.connector.baseUrl',
          type: baseUrlType as any
        },
        {
          name: 'apiKey',
          translationKey: 'tenant.settings.connector.apiKey',
          type: 'text'
        }
      ]
    }
  ];
};

export const webhooksFields: FormField[] = [
  {
    label: '',
    name: 'webhooks',
    type: 'group',
    fields: [
      {
        label: 'Webhook Destination 1',
        name: '[0]',
        type: 'group',
        fields: [
          {
            name: 'apiKey',
            translationKey: 'tenant.settings.webhooks.apiKey',
            type: 'text'
          },
          {
            name: 'url',
            translationKey: 'tenant.settings.webhooks.url',
            type: 'text'
          }
        ]
      },
      {
        label: 'Webhook Destination 2 (Optional)',
        name: '[1]',
        type: 'group',
        fields: [
          {
            name: 'apiKey',
            translationKey: 'tenant.settings.webhooks.apiKey',
            type: 'text'
          },
          {
            name: 'url',
            translationKey: 'tenant.settings.webhooks.url',
            type: 'text'
          }
        ]
      }
    ]
  }
];

export const encryptionFields: FormField[] = [
  {
    name: 'deviceEncryptionKey',
    translationKey: 'tenant.settings.deviceEncryptionKey',
    type: 'textarea'
  },
  {
    name: 'deviceEncryptionIv',
    translationKey: 'tenant.settings.deviceEncryptionIv',
    type: 'textarea'
  }
];

export const formFields = (isAdmin: boolean): TabbedFormField[] => [
  { formFields: generalSettingsFields, tabLabel: 'General' },
  { formFields: credentialFields(isAdmin), tabLabel: 'Credentials' },
  { formFields: webhooksFields, tabLabel: 'Webhooks' },
  { formFields: gatewayFields, tabLabel: 'Gateway' },
  { formFields: encryptionFields, tabLabel: 'Encryption' }
];
