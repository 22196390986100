import { Modal, ModalButton } from 'baseui/modal';
import { Spinner } from 'baseui/spinner';
import { addMinutes, subMinutes } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { DEVICE_LOGS_MODAL, DEVICE_LOGS_MODAL_COPY_BUTTON } from '../../e2e/testIds';
import { themedUseStyletron, themedUseStyletron as useStyletron } from '../../theme/theme';
import { Checkbox } from '../checkbox/Checkbox';
import {
  StyledModalBody,
  StyledModalHeader,
  ModalFooter,
  CenterContainer,
} from '../modal/ModalHelper';
import { Pagination } from '../pagination/Pagination';

import { Bar } from './DeviceLogModalBar';
import { DeviceLogModalContent } from './DeviceLogModalContent';
import { DeviceLogModalInput } from './DeviceLogModalInput';
import { useLogData } from './useLogData';

export interface DeviceLogProps {
  deviceId: string;
  isOpen: boolean;
  onClose: () => void;
}

const NOW = new Date();
const TIMEZONE_OFFSET = NOW.getTimezoneOffset();
const END = addMinutes(NOW, TIMEZONE_OFFSET); // convert current local time to UTC
const START = subMinutes(END, 30); // get the last 30 minutes
const INITIAL_PAGE = 0;
const DEFAULT_PAGE_SIZE = 50;

export const DeviceLogModal = observer(({ deviceId, isOpen, onClose }: DeviceLogProps) => {
  const [, theme] = useStyletron();
  const [css] = themedUseStyletron();
  const [dates, setDates] = useState([START, END]);
  const [refreshActive, setRefreshActive] = useState(false);
  const [page, setPage] = useState(INITIAL_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const {
    loadingState,
    data: rawDeviceLogs,
    dataTableEntries: deviceLogs,
  } = useLogData({
    dates,
    deviceId,
    page,
    pageSize,
    refresh: refreshActive,
  });

  const onPageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setPage(INITIAL_PAGE);
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      autoFocus={false}
      overrides={{
        Close: {
          style: {
            display: 'none',
          },
        },
        Dialog: {
          props: {
            'data-test-id': DEVICE_LOGS_MODAL,
          },
          style: {
            width: 'auto',
          },
        },
      }}
    >
      <StyledModalHeader>{`Device Logs – ${deviceId}`}</StyledModalHeader>

      <StyledModalBody>
        <CenterContainer>
          <DeviceLogModalInput
            value={dates}
            onChange={(nextDates: Date[]) => {
              setDates(nextDates);
              setPage(INITIAL_PAGE);
            }}
          />
          <Bar>
            <Checkbox
              checked={refreshActive}
              onChange={() => setRefreshActive(!refreshActive)}
              overrides={{
                Root: {
                  style: () => ({
                    paddingRight: '10px',
                  }),
                },
              }}
            >
              Automatic refresh
            </Checkbox>
          </Bar>
          {loadingState === 'loading' ? (
            <Spinner />
          ) : (
            <>
              <DeviceLogModalContent
                data={deviceLogs}
                columns={['Timestamp', 'Direction', 'Message']}
                error={loadingState === 'error'}
              />
              <div className={css({ alignSelf: 'flex-end', marginTop: '16px' })}>
                <Pagination
                  page={page}
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                  onPrev={() => setPage(page - 1)}
                  onNext={() => setPage(page + 1)}
                  hasPrev={page > 0}
                  hasNext={deviceLogs.length === pageSize}
                  perPageLabel="Logs per page"
                  recordsLabel="logs"
                />
              </div>
            </>
          )}
        </CenterContainer>
      </StyledModalBody>
      <ModalFooter>
        <ModalButton
          kind="secondary"
          onClick={() => {
            navigator.clipboard.writeText(JSON.stringify(rawDeviceLogs, null, 2));
          }}
          overrides={{
            BaseButton: {
              style: {
                height: theme.sizes.scale600
              },
              props: {
                'data-test-id': DEVICE_LOGS_MODAL_COPY_BUTTON,
              },
            },
          }}
        >
          Copy to Clipboard
        </ModalButton>
        <ModalButton
          kind="primary"
          overrides={{
            BaseButton: {
              style: {
                height: theme.sizes.scale600
              }
            }
          }}
          onClick={onClose}>
          Close
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
});
