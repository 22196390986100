import {
  CategoricalColumn,
  CustomColumn,
  SortDirectionsT,
  SORT_DIRECTIONS,
  StringColumn,
} from 'baseui/data-table';
import { StatefulTooltip } from 'baseui/tooltip';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import { SortColumn, SortDirection } from '../../stores';
import { themedStyled, themedUseStyletron as useStyletron } from '../../theme/theme';
import { config } from '../../utils/config';

import {
  renderBatteryIcon,
  renderLockIcon,
  renderBatteryCompartmentIcon,
  renderLogRowEntry,
  renderCushionStatusIcon,
  renderTopCaseCoverStatusIcon,
  renderTopCaseLockStatusIcon,
  DeviceLogsProps,
  renderVehicleModeIcon,
} from './DeviceListTableIcons';

export type RowDataT = [
  string, // id
  string | undefined, // device type
  string | undefined, // cofiguration
  CoordinateProps | undefined, // Longitude / Latitude
  string | undefined, // last active at
  DeviceStatusProps | undefined, // status
  string | undefined, // firmware
  DeviceLogsProps | undefined, // logs
  DeviceLogsProps | undefined, // errors
];

interface BatteryTextProps {
  $isCritical: boolean;
}

export interface DeviceStatusProps {
  deviceId: string;
  provisioned?: boolean;
  isCharging?: boolean;
  energyLevel?: number;
  isLocked?: boolean;
  isBatteryCompartmentLocked?: boolean;
  factoryData?: {
    cushionStatus?: number;
    topCaseCoverStatus?: number;
    topCaseLockStatus?: number;
  };
  isTestingMode?: boolean;
}

export interface CoordinateProps {
  latitude: number;
  longitude: number;
}

export interface RenderCellProps<T> {
  value: T;
}

const isNotProduction = config.environment !== 'production';

export const columns = [
  StringColumn({
    title: 'IMEI',
    mapDataToValue: (data: RowDataT) => data[0],
    sortable: false,
  }),
  CategoricalColumn({
    title: 'Model',
    mapDataToValue: (data: RowDataT) => data[1] ?? '---',
  }),
  CategoricalColumn({
    title: 'Configuration',
    mapDataToValue: (data: RowDataT) => data[2] ?? '---',
  }),
  CustomColumn({
    title: 'Longitude / Latitude',
    mapDataToValue: (data: RowDataT) => data[3],
    renderCell: ({ value }: RenderCellProps<CoordinateProps | undefined>) => {
      if (!value) {
        return <>{'---'}</>;
      }

      const { latitude, longitude } = value;

      return (
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${latitude.toFixed(4)} / ${longitude.toFixed(4)}`}
        </a>
      );
    },
  }),
  StringColumn({
    title: 'Last active',
    mapDataToValue: (data: RowDataT) => data[4] ?? '---',
  }),
  CustomColumn({
    title: 'Status',
    mapDataToValue: (data: RowDataT) => data[5],
    renderCell: ({ value }: RenderCellProps<DeviceStatusProps | undefined>) => {
      if (!value) {
        return <>{'---'}</>;
      }

      const {
        provisioned,
        isCharging,
        energyLevel,
        isLocked,
        isBatteryCompartmentLocked,
        factoryData,
        isTestingMode,
      } = value;
      const [, theme] = useStyletron();

      const BatteryText = themedStyled<'p', BatteryTextProps>('p', ({ $isCritical }: BatteryTextProps) => ({
        marginBottom: 0,
        minWidth: theme.sizes.scale600,
        marginTop: '0.0625rem', // scale100 / 4 (edge-case)
        marginLeft: theme.sizes.scale200,
        textAlign: 'right',
        color: $isCritical ? theme.colors.error100 : theme.colors.text100,
        overflow: 'hidden',
      }));

      const CustomRowEntry = themedStyled('div', () => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: `-0.125rem`, // scale100 / 2
      }));

      return provisioned ? (
        <CustomRowEntry>
          <BatteryText $isCritical={(energyLevel ?? 0) <= 10}>
            {`${energyLevel ?? 0} %`}
          </BatteryText>
          {renderBatteryIcon(isCharging, energyLevel)}
          {renderLockIcon(isLocked)}
          {renderBatteryCompartmentIcon(isBatteryCompartmentLocked)}

          {/* Yadea Factory */}
          {isNotProduction && renderCushionStatusIcon(factoryData?.cushionStatus)}
          {isNotProduction && renderTopCaseLockStatusIcon(factoryData?.topCaseLockStatus)}
          {isNotProduction && renderTopCaseCoverStatusIcon(factoryData?.topCaseCoverStatus)}

          {renderVehicleModeIcon(isTestingMode)}
        </CustomRowEntry>
      ) : null;
    },
  }),
  CustomColumn({
    title: 'Firmware',
    mapDataToValue: (data: RowDataT) => data[6] ?? '---',
    sortable: false,
    renderCell: ({ value }: RenderCellProps<string | undefined>) => (
      <StatefulTooltip content="IoT / MCU">
        <span style={{ cursor: 'pointer' }}>{value ?? '---'}</span>
      </StatefulTooltip>
    )
  }),
  CustomColumn({
    title: 'Logs',
    mapDataToValue: (data: RowDataT) => data[7],
    renderCell: ({ value }: RenderCellProps<DeviceLogsProps | undefined>) =>
      useObserver(() => renderLogRowEntry(value, 'logs') ?? <>{'---'}</>),
  }),
  // CustomColumn({
  //   title: 'Errors',
  //   mapDataToValue: (data: RowDataT) => data[8],
  //   renderCell: ({ value }: RenderCellProps<DeviceLogsProps | undefined>) =>
  //     useObserver(() => renderLogRowEntry(value, 'errors')),
  // }),
];

const sortColumnMap: Record<SortColumn, number> = {
  [SortColumn.DeviceId]: 0,
  [SortColumn.DeviceType]: 1,
  [SortColumn.Configuration]: 2,
  [SortColumn.LastActive]: 4,
};

export function sortColumnToTableColumnIndex(sortColumn: SortColumn): number {
  return sortColumnMap[sortColumn] ?? 4;
}

export function tableColumnToSortColumnIndex(columnIndex: number): SortColumn {
  const sortColumns = Object.keys(sortColumnMap) as SortColumn[];

  return (
    sortColumns.find((key: SortColumn) => sortColumnMap[key] === columnIndex) ??
    SortColumn.LastActive
  );
}

export function sortDirectionToTableSortDirection(sortDirection: SortDirection): SortDirectionsT {
  // For some reason, when we pass DESC to the table it actual sorts ascending,
  // and when we pass ASC it sorts descending... 🤔
  return sortDirection === SortDirection.Asc ? SORT_DIRECTIONS.DESC : SORT_DIRECTIONS.ASC;
}

export function resolveNextSortDirection(
  currentSortColumn: SortColumn,
  nextSortColumn: SortColumn,
  currentSortDirection: SortDirection,
): SortDirection {
  return nextSortColumn === currentSortColumn && currentSortDirection === SortDirection.Asc
    ? SortDirection.Desc
    : SortDirection.Asc;
}
